export const csvJSON = (csv) => {
  const lines = csv.split("\n");

  const result = [{}];

  const headers = lines[0].split(",");

  for (let i = 1; i < lines.length; i++) {
    const obj: any = {};
    const currentline = lines[i].split(",");
    if (currentline.length > 1) {
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j].toLowerCase().trim()] = currentline[j];
      }
    }
    result.push(obj);
  }
  return JSON.stringify(result);
};

export const checkNullInObject = (obj) => {
  if (Array.isArray(obj)) {
    obj.forEach((item) => checkNullInObject(item));
  } else if (typeof obj == "object") {
    for (const key in obj) {
      if (typeof obj[key] == "string" && obj[key].toString() == "null") {
        obj[key] = null;
      }
    }
  }
};

export const checkDiff = (obj1, obj2, ) => {
  console.log(obj1, obj2);
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    obj1.forEach((item1) => {
      const item2 = obj2.find((obj2Item) => {
        if (item1.id) {
          return obj2Item.id == item1.id;
        } else {
          return checkDiff(obj2Item, item1);
        }
      });
      if(item2){
        checkDiff(item1, item2);
      }
    });
  } else if (
    typeof obj1 == "object" &&
    typeof obj2 == "object" &&
    obj1 != null
  ) {
    for (const key in obj1) {
      if (
        typeof obj1[key] == "object" &&
        typeof obj2[key] == "object" &&
        obj1[key] != null &&
        obj2[key] != null
      ) {
        if (Object.keys(obj1[key]).length == 0) {
          delete obj1[key];
        }
        if (Object.keys(obj2[key]).length == 0) {
          delete obj2[key];
        }
        checkDiff(obj1[key], obj2[key]);
        if (Object.keys(obj1[key]).length == 0) {
          delete obj1[key];
        }
        if (Object.keys(obj2[key]).length == 0) {
          delete obj2[key];
        }
      } else {
        if (obj1[key] == obj2[key]) {
          delete obj1[key];
          delete obj2[key];
        }
      }
    }
  }
};
export const getError = (err) => {
  console.log(err);
  let errStr = "";
  if (err.data) {
    errStr = getError(err.data);
  } else if (Array.isArray(err)) {
    errStr = err
      .map((item) => {
        return getError(item);
      })
      .join(", ");
  } else if (typeof err === "object") {
    Object.keys(err).forEach((key) => {
      if (key.toUpperCase() != "NON_FIELD_ERRORS") {
        errStr += "<strong>" + key.toUpperCase() + "</strong>" + ": ";
      }
      errStr += getError(err[key]) + "<br>";
    });
  } else {
    errStr = err;
  }
  return errStr;
};
export const getSuccess = (response, defaultMsg) => {
  return (
    response.data.message ||
    defaultMsg ||
    "Form has been successfully submitted!"
  );
};
export const uuidv4 = () => {
  let d = new Date().getTime(); //Timestamp
  let d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};
